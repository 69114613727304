body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-card-meta-title {
  white-space: unset !important;
  font-size: large
}

#mainmenu {
  display: inline !important;
}

#togglemenu {
  display: none !important;
}

@media screen and (max-width:900px),
screen and (max-height:500px) {
  #mainmenu {
      display: none !important;
  }
  #togglemenu {
      display: inline !important;
  }
  #about_content{
    padding: 50px 20px !important
  }
}

